import React, { useEffect } from "react";
import Layout from "../layout.pt";
import SEO from "../seo";
import { Link, graphql } from "gatsby";
import { WOW } from "wowjs";
import moment from "moment";

const BlogPagePT = ({ data }) => {
    const blogPage = data.wbapi.Cottonhat_blog;

    useEffect(() => {
        const wow = new WOW({
            boxClass: "wow",
            animateClass: "animate",
            offset: 100,
            mobile: false,
            live: true,
        });

        wow.init();
    });

    return (
        <Layout>
            <SEO title={`${blogPage.title.pt_PT ? blogPage.title.pt_PT : blogPage.title}`} description={`${blogPage.metaDescription && blogPage.metaDescription.pt_PT ? blogPage.metaDescription.pt_PT : ""}`} ogImage={`${blogPage.photo.url}?w=1170&h=600&fit=crop`} lang="pt" />

            <div className="breadcaump-area pt--260 pt_md--200 pt_sm--200 pb--160 pb_sm--100 bg_color--18 breadcaump-title-bar breadcaump-title-white">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="breadcaump-inner text-center">
                                <div className="breadcrumb-insite">
                                    <div className="header mb--40 text-center">
                                        {blogPage.htmlTitle ? (
                                            <h1 className="heading heading-h3 font-32 text-white">
                                                <div id="blog-html" dangerouslySetInnerHTML={{ __html: blogPage.htmlTitle.pt_PT ? blogPage.htmlTitle.pt_PT : blogPage.htmlTitle }} />
                                            </h1>
                                        ) : (
                                            <h1 className="heading heading-h3 font-32 text-white">{blogPage.title.pt_PT ? blogPage.title.pt_PT : blogPage.title}</h1>
                                        )}

                                        <div className="post-meta text-white mt--20">
                                            <div className="post-date">{moment(blogPage.creat_default.createdAt).format("LL")}</div>
                                            <div className={moment(blogPage.creat_default.createdAt)._isValid ? `post-category b` : `post-category`}>
                                                {blogPage.Category.map((item, index) => (
                                                    <Link key={index}>
                                                        {item.labelPT ? item.labelPT : item.label ? item.label : null}
                                                        {index < blogPage.Category.length - 1 && <>,&nbsp;</>}
                                                    </Link>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="brook-blog-details-area space_dec--100 pt_md--80 pt_sm--80">
                <div className="container">
                    <div className="row mb--85">
                        <div className="col-lg-12">
                            <div className="blog-modern-layout">
                                <div className="thumbnail mb--60">
                                    <img className="w-100" src={`${blogPage.photo.url}?w=1170&h=600&fit=crop`} alt={blogPage.title.pt_PT ? blogPage.title.pt_PT : blogPage.title} />
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-2 order-2 order-lg-1 mt_md--40 mt_sm--40">
                            <div className="blog-post-return-button">
                                <Link to="/pt/blog/">
                                    <i className="fa fa-arrow-left"></i> Voltar
                                </Link>
                            </div>
                        </div>
                        <div className="col-lg-8 order-1 order-lg-2">
                            <div className="blog-details-wrapper">
                                <article className="blog-post blog-modern-layout">
                                    <div className="content basic-dark2-line-1px pb--50 mb--35">
                                        <div id="blog-html" className="inner wow move-up" dangerouslySetInnerHTML={{ __html: blogPage.html.pt_PT ? blogPage.html.pt_PT : blogPage.html }} />
                                    </div>
                                    <div className="blog-footer wow move-up">
                                        <div className="row">
                                            <div className="col-lg-12 col-12">
                                                <div className="post-tag d-flex align-items-center justify-content-center">
                                                    <h6 className="heading heading-h6">TAGS:</h6>
                                                    <div className="blog-tag-list pl--5">{blogPage.tags}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </article>
                            </div>
                            {/* <div className="post-nav-lisnt mb--45 wow move-up">
                                <div className="nav-item previous">
                                    <Link to="/pt/blog/">
                                        <div className="link-text">
                                            <span className="fa fa-arrow-left"></span>
                                            <p className="">Prev</p>
                                        </div>
                                        <span>MISSING RELATED</span>
                                    </Link>
                                </div>
                                <div className="nav-item next mt_sm--30">
                                    <Link to="/pt/blog/">
                                        <div className="link-text">
                                            <p className="">Next</p>
                                            <span className="fa fa-arrow-right"></span>
                                        </div>
                                        <span>MISSING RELATED</span>
                                    </Link>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default BlogPagePT;

export const query1 = graphql`
    query($_id: ID!) {
        wbapi {
            Cottonhat_blog(project: "592d4cf5b549b452a450d829", id: $_id) {
                project_id
                title
                html
                _id
                uri
                tags
                htmlTitle
                normalized_name
                creat_default {
                    createdAt
                }
                Category {
                    code
                    label
                }
                photo {
                    url
                    edition_options {
                        crop_options {
                            height
                            width
                            x
                            y
                        }
                    }
                }
            }
        }
    }
`;
